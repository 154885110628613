import React, { useEffect, useRef, useState } from "react";

import {
  Footer,
  Header,
  Global,
  Main,
  News,
  Playlist,
  Share,
  Player,
  Voices,
} from "../components";
import { graphql } from "gatsby";
import PlayerContext, { FableProps } from "../context";
import { sortBy } from "lodash";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { SEO } from "../components/seo";
import useMedia from "../hooks/useMedia";
import PlaylistMobile from "../components/Playlist/PlaylistMobile";

const IndexPage = ({ data }: any) => {
  const [state, setState] = useState<any>({
    news: null,
    fables: null,
    voices: null,
    bg: null,
    main: null,
  });
  const [currentFable, setCurrentFable] = useState<FableProps>({
    title: "",
    nation: "",
    num: "",
    artist: "",
    href: "",
    src: "",
    duration: "",
    cover: "",
    background: "",
    attachments: [],
  });
  const mobile = useMedia("(min-width: 702px)");

  const ref = useRef<HTMLDivElement>(null);

  const handleCurrentFable = (fable: any) => {
    setCurrentFable(fable);
  };
  useEffect(() => {
    if (data) {
      const news = data.allMarkdownRemark.edges.filter(
        (item: any) => item.node.frontmatter.type === "news"
      );
      const fables = data.allMarkdownRemark.edges.filter(
        (item: any) => item.node.frontmatter.type === "fables"
      );
      const voices = data.allMarkdownRemark.edges.filter(
        (item: any) => item.node.frontmatter.type === "voices"
      );
      const fablesSort = sortBy(fables, (item) => item.node.frontmatter.num);
      const newsSort = sortBy(news, (item) => item.node.frontmatter.num);
      const voicesSort = sortBy(voices, (item) => item.node.frontmatter.num);
      const bg = data.allMarkdownRemark.edges.filter(
        (item: any) => item.node.frontmatter.type === "bg"
      );
      const main = data.allMarkdownRemark.edges.filter(
        (item: any) => item.node.frontmatter.type === "main"
      );
      setCurrentFable(fablesSort[0].node.frontmatter);
      // @ts-ignore
      setState({
        news: newsSort,
        fables: fablesSort,
        voices: voicesSort,
        bg,
        main,
      });
    }
  }, [data]);

  return (
    <Global>
      <PlayerContext.Provider
        value={{ currentFable, setCurrentFable: handleCurrentFable }}
      >
        <Header />
        <Main main={state.main} />
        {state.fables && (
          <>
            {mobile ? (
              <>
                <Player customRef={ref} />
                <Playlist fables={state.fables} customRef={ref} />
              </>
            ) : (
              <PlaylistMobile fables={state.fables} customRef={ref} />
            )}
          </>
        )}
        {/* {state.news && <News news={state.news} />} */}
        {state.bg && <Share bg={state.bg} />}
        <Footer voices={state.voices} />
      </PlayerContext.Provider>
    </Global>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
export const query = graphql`
  query gGetNews {
    allMarkdownRemark {
      edges {
        node {
          id
          html
          frontmatter {
            title
            nation
            num
            artist
            href
            src
            duration
            name
            job
            attachments {
              publicURL
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  placeholder: DOMINANT_COLOR
                  width: 453
                  quality: 100
                )
              }
            }
            image_main_1 {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  placeholder: NONE
                  width: 1290
                  quality: 100
                )
              }
            }
            image_main_2 {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  placeholder: NONE
                  width: 1009
                  quality: 100
                )
              }
            }
            image_main_3 {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  placeholder: NONE
                  width: 1014
                  quality: 100
                )
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                  width: 296
                  quality: 70
                )
              }
            }
            background {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  placeholder: DOMINANT_COLOR
                  width: 1440
                  quality: 70
                  breakpoints: [600, 900, 1440]
                )
              }
            }
            type
          }
        }
      }
    }
  }
`;
